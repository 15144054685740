<template>
  <div class="row">
    <div class="col-12">
      <Toast ref="mensajeToast"></Toast>
      <Alerts ref="alert"></Alerts>
      <ModalVideo ref="modalVideo"></ModalVideo>
      <Confirm ref="confirm" :method="store"/>
      <transition name="slide">
        <form ref="frmNuevo" novalidate class="was-validated">
          <div class="card border-warning">
            <div class="card-header">
              <strong class="text-primary">
                Planilla de Calificaciones
              </strong>
              <div class="card-header-actions">
                <button @click="$refs.modalVideo.mostrarVideo('video-alumno-nuevo.mp477777777777777777')"
                        class="btn btn-outline-info" title="Ver video de ayuda">
                  <font-awesome-icon icon="fas fa-question-circle"/>
                  Ayuda
                </button>
              </div>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-4">
                  <label class="form-label mr-1">Carrera: </label>
                  <strong>{{ this.carrera }}</strong>
                </div>
                <div class="col-1">
                  <label class="form-label mr-1">Docente: </label>
                </div>
                <div class="col-5">
                  <input type="text" v-model="notas.docente" class="form-control"
                         required
                         @change="notas.docente=notas.docente.toUpperCase()">
                  <div class="invalid-feedback">
                    El nombre del docente es requerido
                  </div>
                </div>
                <div class="col-2">
                  <label class="form-label mr-1">Semestre: </label>
                  <strong>{{ this.semestre }}</strong>
                </div>
              </div>
              <hr/>
              <div class="row">
                <div class="col-5">
                  <label class="form-label mr-1">Materia:</label>
                  <strong>{{ this.materia }}</strong>
                </div>
                <div class="col-2">
                  <label class="form-label mr-1">Gestión:</label>
                  <strong>{{ this.$route.params.gestion }}</strong>
                </div>
                <div class="col-2">
                  <label class="form-label mr-1">Grupo:</label>
                  <strong>{{ this.grupo }}</strong>
                </div>
                <div class="col-3">
                  <label class="form-label mr-1">Plan:</label>
                  <strong>{{ this.plan }}</strong>
                </div>
              </div>
              <hr/>
              <template v-if="this.alumnos.length>0">
                <table class="table table-sm table-bordered table-hover align-middle">
                  <tr>
                    <th class="text-center">Nro</th>
                    <th class="text-center">Primer<br/>Apellido</th>
                    <th class="text-center">Segundo<br/>Apellido</th>
                    <th class="text-center">Nombre(s)</th>
                    <th :class="'text-center '+($route.params.parcial=='1'?'text-success':'')"
                        v-if="parametros.nota_maxima_parcial1>0">
                      Parcial<br/>1
                    </th>
                    <th :class="'text-center '+($route.params.parcial=='2'?'text-success':'')"
                        v-if="parametros.nota_maxima_parcial2>0">Parcial<br/>2
                    </th>
                    <th :class="'text-center '+($route.params.parcial=='3'?'text-success':'')"
                        v-if="parametros.nota_maxima_parcial3>0">Parcial<br/>3
                    </th>
                    <th class="text-center">Examen<br/>Final</th>
                    <th class="text-center">Nota<br/>Final</th>
                    <th class="text-center">Segunda<br/>Instancia</th>
                  </tr>
                  <tbody class="table-group-divider">
                  <tr v-for="(alumno,index) in alumnos">
                    <td class="text-center">{{ index + 1 }}</td>
                    <td>{{ alumno.apellido1 }}</td>
                    <td>{{ alumno.apellido2 }}</td>
                    <td>{{ alumno.nombres }}</td>
                    <td class="text-center" v-if="parametros.nota_maxima_parcial1>0">
                      <template v-if="$route.params.parcial==='1' && alumno.seg_instancia===0">
                        <input type="number" step="1" :min="minimo" :max="maximo" value=""
                               v-model="notas.notas[index]" @change="registraIdAlumno(index,alumno.id)"
                               required class="form-control text-center">
                        <div class="invalid-feedback">
                          La nota debe estar entre {{ minimo }} y {{ maximo }}.
                        </div>
                      </template>
                      <template v-else>{{ alumno.parcial1 }}</template>
                    </td>
                    <td class="text-center" v-if="parametros.nota_maxima_parcial2>0">
                      <template v-if="$route.params.parcial==='2' && alumno.seg_instancia===0">
                        <input type="number" step="1" :min="minimo" :max="maximo" value=""
                               v-model="notas.notas[index]" @change="registraIdAlumno(index,alumno.id)"
                               required class="form-control text-center">
                        <div class="invalid-feedback">
                          La nota debe estar entre {{ minimo }} y {{ maximo }}.
                        </div>
                      </template>
                      <template v-else>{{ alumno.parcial2 }}</template>
                    </td>
                    <td class="text-center" v-if="parametros.nota_maxima_parcial3>0">
                      <template v-if="$route.params.parcial==='3' && alumno.seg_instancia===0">
                        <input type="number" step="1" :min="minimo" :max="maximo" value=""
                               v-model="notas.notas[index]" @change="registraIdAlumno(index,alumno.id)"
                               required class="form-control text-center">
                        <div class="invalid-feedback">
                          La nota debe estar entre {{ minimo }} y {{ maximo }}.
                        </div>
                      </template>
                      <template v-else>{{ alumno.parcial3 }}</template>
                    </td>
                    <td class="text-center">
                      <template v-if="$route.params.parcial==='examen_final' && alumno.seg_instancia===0">
                        <input type="number" step="1" :min="minimo" :max="maximo" value=""
                               v-model="notas.notas[index]" @change="registraIdAlumno(index,alumno.id)"
                               required class="form-control text-center">
                        <div class="invalid-feedback">
                          La nota debe estar entre {{ minimo }} y {{ maximo }}.
                        </div>
                      </template>
                      <template v-else>{{ alumno.examen_final }}</template>
                    </td>
                    <td class="text-center">
                      <strong v-if="alumno.nota_final >= 51" class="text-success">{{ alumno.nota_final }}</strong>
                      <strong v-else class="text-danger">{{ alumno.nota_final }}</strong>
                    </td>
                    <td class="text-center">
                      <template v-if="$route.params.parcial==='seg_instancia' && alumno.nota_final < parametros.nota_aprobacion_pregrado && alumno.nota_final >= parametros.nota_minima">
                        <input type="number" step="1" :min="0" :max="parametros.nota_aprobacion_pregrado" value=""
                               v-model="notas.notas[index]" @change="registraIdAlumno(index,alumno.id)"
                               required class="form-control text-center">
                        <div class="invalid-feedback">
                          La nota debe estar entre {{ "0" }} y {{ parametros.nota_aprobacion_pregrado }}.
                        </div>
                      </template>
                      <template v-else-if="alumno.nota_final < parametros.nota_minima">
                        <span class="text-danger">la nota mínima para segunda instancia es {{ parametros.nota_minima }}</span>
                      </template>
                      <template v-else>
                        <template v-if="alumno.nota_final < 51">
                          <strong v-if="alumno.seg_instancia >= 51" class="text-success">{{
                              alumno.seg_instancia
                            }}</strong>
                          <strong v-else class="text-danger">{{ alumno.seg_instancia }}</strong>
                        </template>
                        <template v-else> -</template>
                      </template>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <div class="row">
                  <div class="col-6">
                    Registrado por: <strong class="text-primary">{{ usuario_registro }}</strong>
                  </div>
                  <div class="col-6 text-right">
                    &Uacute;ltima modificaci&oacute;n: <strong class="text-primary">{{ ultima_modificacion }}</strong>
                  </div>
                </div>

              </template>
              <div v-if="this.alumnos.length<1" class="row">
                <div class="col  text-center text-danger">
                  <strong>No existen materias para mostrar</strong>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <button v-if="$route.params.parcial==='seg_instancia' && cuenta_seg_instancia<1"
                      class="btn btn-warning mr-2" type="button">
                No se puede registrar notas de segunda instancia<br/>porque todos los alumnos aprobaron esta materia
              </button>
              <CButton v-else class="mr-2" color="primary" @click="store()">Grabar</CButton>
              <CButton color="danger" @click="goBack">Regresar a la pantalla anterior</CButton>
            </div>
          </div>
        </form>
      </transition>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Toast from "@/views/notifications/Toast";
import Alerts from "@/views/notifications/Alerts";
import ModalVideo from "@/views/notifications/ModalVideo";
import Confirm from "@/views/notifications/Confirm";
import Table from "@/views/base/Table";

var moment = require('moment');
var modalPdf = null;

export default {
  name: 'EditUser',
  components: {Table, Confirm, ModalVideo, Alerts, Toast},
  props: {
    caption: {
      type: String,
      default: 'User id'
    },
  },
  data: () => {
    return {
      alumnos: [],
      carrera: '',
      plan: '',
      materia: '',
      grupo: '',
      parcial: '',
      semestre: '',
      minimo: 0,
      maximo: 25,
      maximo_seg_instancia: 51,
      usuario_registro: '',
      ultima_modificacion: '',
      cuenta_seg_instancia: 0,
      notas: {
        docente: '',
        estudiantes: [],
        notas: []
      },
      parametros: {
        grupo: 'rangonotas',
        nota_minima: 35,
        nota_aprobacion_pregrado: 51,
        nota_aprobacion_postgrado: 65,
        nota_maxima_parcial1: 25,
        nota_maxima_parcial2: 25,
        nota_maxima_parcial3: 25,
        nota_maxima_examenfinal: 25,
        nota_maxima: 100
      },
    }
  },
  methods: {
    goBack() {
      modalPdf.$router.push({
        path: '/docenteregistronotas',
      });
    },
    store() {
      if (!modalPdf.$refs.frmNuevo.checkValidity()) {
        modalPdf.$refs.frmNuevo.classList.add('was-validated');
      } else {
        modalPdf.$refs.alert.show('Grabando materias asignadas');
        let params = this.$route.params;
        axios.post(this.$apiAdress + '/api/docentematerianotas/'
            + localStorage.sedeSeleccionada + '/' + params.gestion + '/' + params.carrera_id + '/' + params.plan_id + '/' + params.materia_id + '/' + params.grupo + '/' + params.parcial
            + '/store?token=' + localStorage.getItem("api_token"),
            modalPdf.notas
        )
            .then(function (response) {
              modalPdf.$refs.alert.hide();
              modalPdf.docente = {
                facultad_id: 0,
                carrera_id: 0,
                plan_id: 0,
                gestion: '',
                traspaso_id: 0,
                sede_id: localStorage.sedeSeleccionada,
                materia_id: 0,
                grupo: ":: SELECCIONAR ::",
              };
              //modalPdf.actualizarListaAlumnos(modalPdf.$route.params)
              modalPdf.$refs.mensajeToast.makeToast('', 'Notas grabadas satisfactoriamente.', 'success');
              modalPdf.$router.push('/docenteregistronotas')
            })
            .catch(function (error) {
              modalPdf.$refs.alert.hide();
              modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
            });
      }
    },
    registraIdAlumno: function (index, id) {
      modalPdf.notas.estudiantes[index] = id;
    },
    actualizarListaAlumnos: function (params) {
      axios.get(this.$apiAdress + '/api/docentematerianotas/' + localStorage.sedeSeleccionada + '/' + params.gestion + '/' + params.carrera_id + '/' + params.plan_id + '/' + params.materia_id + '/' + params.grupo + '/' + params.parcial + '/parciallistarpordocente?token=' + localStorage.getItem("api_token"))
          .then(function (response) {
            let valorPorDefecto = [{value: '', label: ':: SELECCIONAR ::'}];
            modalPdf.alumnos = response.data.alumnos
            modalPdf.carrera = response.data.carrera
            modalPdf.plan = response.data.plan
            modalPdf.materia = response.data.materia;
            modalPdf.semestre = response.data.semestre;
            modalPdf.parcial = response.data.parcial;
            modalPdf.usuario_registro = response.data.usuario_registro;
            modalPdf.ultima_modificacion = response.data.ultima_modificacion;
            modalPdf.notas.docente = response.data.docente;
            modalPdf.notas.notas = []
            modalPdf.notas.estudiantes = []
            modalPdf.cuenta_seg_instancia = 0
            response.data.alumnos.forEach(function (data, index) {
              modalPdf.notas.notas.push(data.nota);
              modalPdf.notas.estudiantes.push(data.id);
              if (params.parcial === 'seg_instancia') {
                if (data.nota_final < 51) {
                  modalPdf.cuenta_seg_instancia++;
                }
              }
            });
          })
          .catch(function (error) {
            modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
          });
    },
    listarParametros: function (gestion) {
      //axios.get(this.$apiAdress + '/api/parametros/' + localStorage.sedeSeleccionada + '/' + grupo + '/listar?token=' + localStorage.getItem("api_token"))
      axios.get(this.$apiAdress + '/api/docentematerianotas/' + localStorage.sedeSeleccionada + '/' + gestion + '/listarrangonotas?token=' + localStorage.getItem("api_token"))
          .then(function (response) {
            modalPdf.parametros = response.data;
            switch (modalPdf.$route.params.parcial) {
              case '1':
                modalPdf.minimo=0
                modalPdf.maximo = modalPdf.parametros.nota_maxima_parcial1;
                break;
              case '2':
                modalPdf.minimo=0
                modalPdf.maximo = modalPdf.parametros.nota_maxima_parcial2;
                break;
              case '3':
                modalPdf.minimo=0
                modalPdf.maximo = modalPdf.parametros.nota_maxima_parcial3;
                break;
              case 'examen_final':
                modalPdf.minimo=0
                modalPdf.maximo = modalPdf.parametros.nota_maxima_examenfinal;
                break;
              case 'seg_instancia':
                modalPdf.minimo = modalPdf.parametros.nota_minima;
                modalPdf.maximo = modalPdf.parametros.nota_aprobacion_pregrado;
                break;
            }
          })
          .catch(function (error) {
            modalPdf.$refs.mensajeToast.mostrarError(error, modalPdf);
          });
    },
  },
  computed: {},
  mounted: function () {
    modalPdf = this;
    modalPdf.sede_id = localStorage.sedeSeleccionada;
    let params = this.$route.params;
    modalPdf.grupo = params.grupo;
    modalPdf.listarParametros(this.$route.params.gestion)
    modalPdf.actualizarListaAlumnos(this.$route.params)
  }
}

</script>
<style>
.table td div.form-group {
  margin-bottom: 0px !important;
}
</style>